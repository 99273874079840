/* Coverage:
 Statements: 0%
 Branches: 0%
 Functions: 0%
 (Generated by scripts/generate-coverage-comments.js - Do not edit this comment)
*/
import React, { type ReactNode, createContext } from 'react'
import type { DrillTypeRecord, TagRecord, TrackRecord } from '~/lib/schema'
import type { GenreTree, ProgramData } from '~/lib/types'

export interface ServerContextType {
  tracksById: Record<number, TrackRecord>
  drillTypesById: Record<number, DrillTypeRecord>
  genreTreeById: Record<number, GenreTree>
  genreTreeOrdered: GenreTree[]
  programsById: Record<number, ProgramData>
  allTags: TagRecord[]
  isTest: boolean
  supabaseUrl: string
  supabaseAnonKey: string
}

export const ServerContext = createContext<ServerContextType>({} as ServerContextType)

export const ServerContextProvider: React.FC<{
  children: ReactNode
  genreTreeById: Record<number, GenreTree>
  genreTreeOrdered: GenreTree[]
  programsById: Record<number, ProgramData>
  allTags: TagRecord[]
  tracksById: Record<number, TrackRecord>
  drillTypesById: Record<number, DrillTypeRecord>
  isTest: boolean
  supabaseUrl: string
  supabaseAnonKey: string
}> = ({
  children,
  genreTreeById,
  genreTreeOrdered,
  programsById,
  allTags,
  tracksById,
  drillTypesById,
  isTest,
  supabaseUrl,
  supabaseAnonKey,
}) => {
  return (
    <ServerContext.Provider
      value={{
        tracksById,
        drillTypesById,
        genreTreeById,
        genreTreeOrdered,
        programsById,
        allTags,
        isTest,
        supabaseUrl,
        supabaseAnonKey,
      }}
    >
      {children}
    </ServerContext.Provider>
  )
}
